.logo {
    position: absolute;
    top: 3vh;
    left: 2vh;
    width: 25vh;
    height: 8vh;
}

.language-selector {
    /* display: none; */
    position: absolute;
    top: 5vh;
    right: 10vh;
    height: 10vh;
    justify-content: space-between;
}



.lastbutton {
    width: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-container {
    display: flex;
    flex-direction: column;
}

.tablediv {
    margin-top: 3vh;
    background-color: rgb(255, 255, 255);
    width: 55vh;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 2vh 2vh 1vh;
    box-shadow: 2px 2px 4px #0000002d;
}

/* .user-info {
    background-color: #e3e7f0;
} */

/* .content-container {
    padding-top: 1vh;
    height: 40vh;
} */


button {
    background-color: #51AFCE;
    color: white;
    width: 29vh;

}

h2 {
    margin: 2vh;
}



.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large;
    font-family: montserrat semi-bold;
    padding: 1vh;
}

.content-container {
    /* background-color: #b5c5fc; */
    padding: 10vh;
    border-radius: 2vh;
    height: 50vh;
    width: 40vh;
    margin-top: 2vh;
    padding-top: 2vh;
}

.user-info {
    padding-top: 5vh;
}

.image-folder {
    display: grid;
    grid-template-columns: auto auto;
    /* align-items: center; */
    background-color: white;
    width: 60vh;
    border-radius: 5px;
}

.herocorp {
    justify-self: start;
    height: 6vh;
    width: 20vh;
    margin: 2vh;
    /* padding-left: 1vh; */

}


.fileicon {
    justify-self: end;
    height: 5vh;
    width: 6vh;


}

.blue-row {
    background-color: #e8f3f5;
    color: black;
}

.white-row {
    background-color: #e3e7f5;
    color: rgb(0, 0, 0);
}

.lastline {
    padding-left: 15vh;
    color: rgb(247, 100, 100);
}


/* tr {

    height: 4vh;
    width: 15vh;
} */

td {
    padding-left: 2vh;
    width: 50vh;
}

.detailtable {
    width: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 4px #0000002d;
}

/* .image-folder {
    display: flex;
    justify-content: space-between;


}

.axislogo {
    height: 10vh;
    width: 15vh;
    left: 1vh
}

.fileicon {
    height: 4vh;
    width: 6vh;
    right: 2vh
}

.Creditcardicon {
    height: 4vh;
    width: 6vh;
} */

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.close {
    cursor: pointer;
}

.page-container.modal-open {
    pointer-events: none;
}

.popuptable {
    width: 70vh;
    background-color: rgb(189, 211, 212);
    border-radius: 1vh;
    padding: 1vh;
}






@media (max-width: 300px) {
    .page-container {
        /* padding-left: 20vh; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60vh;
    }

    .logo {
        padding-left: 1px;
        width: 15vh;
        height: 6vh;
    }

    button {
        width: 25vh;
        margin-left: 4vh;

    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30vh;
    }

    .language-selector {

        position: relative;
        top: 5vh;
        left: 20vh;
        height: 10vh;
        justify-content: space-between;
    }

    .lastbutton {
        width: 50vh;

        display: flex;
        justify-content: center;
        align-items: center;
    }


    /* table {
        width: 65vh;
    } */

    .image-folder {

        grid-template-columns: auto auto;
        background-color: white;
        width: 52vh;
        border-radius: 5px;
    }

    .fileicon {
        margin-top: 3vh;
    }

    .language-selector {

        position: absolute;

        height: 10vh;
        left: 44vh;

    }

    .lastline {
        padding-left: 10vh;
    }


}

@media (min-width: 400px) {
    .page-container {
        /* padding-left: 20vh; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60vh;
    }

    .logo {
        padding-left: 1vh;
    }

    button {
        width: 22vh;
        margin-left: 4vh;
        height: 6vh;

    }

    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30vh;
    }

    .language-selector {

        position: relative;
        top: 5vh;
        left: 10vh;
        height: 10vh;
        justify-content: space-between;
    }

    .lastbutton {
        width: 48svh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* table { 
        width: 60vh;

         padding-left: 4vh; 

     }  */

    .image-folder {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        background-color: white;
        width: 55vh;
        padding-left: 2vh;
        border-radius: 5px;
    }

    .language-selector {

        position: absolute;
        /* top: 5vh; */
        /* right: 50vh; */
        height: 10vh;
        left: 44vh;
        /* justify-content: space-between; */
    }

    .lastline {
        padding-left: 10vh;
    }
}