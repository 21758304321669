.page-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: rgb(103, 198, 227); */
    border-radius: 2vh;
    width: 80vh;
    background-color: #ecf6ff;
}

h5 {
    margin-top: 0vh;
}

h6 {
    margin-top: 1vh;
}

.checkbox-label {
    display: inline-flex;
    align-items: center;

}

.checkbox {
    margin-right: 2vh;

}

input[type=checkbox] {
    width: 30px;
    height: 30px;
    margin-bottom: 3vh;
}

.language-selector {

    position: absolute;
    top: 5vh;
    right: 10vh;
    height: 10vh;
    /* justify-content: space-between; */
}

.globe-icon {
    position: fixed;
    top: 2vh;
    right: 17vh;
    height: 10vh;
}

.content-container {
    background-color: #ffffff;
    /* padding: 10vh; */
    border-radius: 2vh;
    box-shadow: 2px 2px 4px #0000002d;
    height: 50vh;
    width: 40vh;
    margin-top: 2vh;
}

.logo {
    position: absolute;
    top: 3vh;
    left: 3vh;
    width: 25vh;
    height: 8vh;

}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large;
    font-family: montserrat semi-bold;
    /* margin: 3rem; */
    /* padding: 3vh; */
}

h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vh;
}

label {
    margin: 1vh;
}

input {
    /* margin: 1vh; */
    border-radius: 2vh;
    padding: 1vh;
    margin-bottom: 1vh;
    border-color: rgba(112, 112, 245, 0.301);

}

.enternunber {
    margin-bottom: 1vh;
}

button {
    background-color: #51AFCE;
    /* margin: 1vh; */

    border-color: rgb(223, 245, 255);
    height: 5vh;
    border-radius: 1.5vh;

}

.verifybutton {
    margin-right: 3vh;
}

.error-message {
    color: red;
}

@media only screen and (max-width: 600px) {
    .page-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        /* background-color: rgb(103, 198, 227); */
        border-radius: 2vh;
        width: 80vh;
        background-color: #ecf6ff;
    }

    .logo {
        position: absolute;
        top: 3vh;
        margin-left: 8vh;
        width: 25vh;
        height: 8vh;

    }

    .language-selector {

        position: absolute;
        top: 5vh;
        right: 10vh;
        height: 10vh;
        /* justify-content: space-between; */
    }

    .globe-icon {
        position: fixed;
        top: 2vh;
        right: 21vh;
        height: 10vh;
    }

    .content-container {
        background-color: #ffffff;
        /* padding: 10vh; */
        border-radius: 2vh;
        box-shadow: 2px 2px 4px #0000002d;
        height: 50vh;
        width: 20vh;
        margin-top: 2vh;
        margin-left: 10vh;
    }

    h5 {
        margin-left: 6vh;
    }
}